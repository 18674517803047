export const apiUrl = import.meta.env.VITE_APP_API // Защита, от подмены адреса отправки кредов
export const passportApi = import.meta.env.VITE_APP_PASSPORT_API
export const prosystemApi = import.meta.env.VITE_APP_PROSYSTEM_API_URL
export const sourceProsystem = import.meta.env.VITE_APP_SOURCE_PROSYSTEM_URL
export const sourcePromarket = import.meta.env.VITE_APP_SOURCE_PROMARKET_URL
export const sourceProlearning = import.meta.env.VITE_APP_SOURCE_PROLEARNING_URL
export const sourceF3PRO = import.meta.env.VITE_APP_SOURCE_F3PRO_URL
export const sourceProanalytics = import.meta.env.VITE_APP_SOURCE_PROANALYTICS_URL
export const profileUrl = import.meta.env.VITE_APP_PROFILE_URL
export const profileApiUrl = import.meta.env.VITE_APP_PROFILE_API
export const appMode = import.meta.env.VITE_APP_MODE
export const passportUrl = import.meta.env.VITE_APP_PASSPORT_URL