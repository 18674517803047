import { createTheme } from '@mui/material/styles'

export const themeLightScrollStyle = {
  '&::-webkit-scrollbar-track': {
    borderRadius: '10px',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
  },
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
    borderRadius: '10px',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '10px',
    backgroundColor: 'rgba(85, 85, 85, 0.31)',
  }
}

export const customTheme = createTheme({
  typography: {
    fontFamily: 'Inter, sans-serif',
  },

  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({theme}) => ({
          borderRadius: '8px',
          height: '54px',
          '&:hover > .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)'
          }
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'none',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: ({theme}) => ({
          fontFamily: theme.typography.fontFamily,
          cursor: 'pointer',
          textDecoration: 'unset',
        })
      }
    },
    MuiStack: {
      // @ts-ignore
      styleOverrides: {
        root: themeLightScrollStyle
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: ({theme}) => ({
          bgcolor: theme.palette.primary.main
        })
      }
    }
  },

  palette: {
    background: {
      default: '#eff3f8',
      paper: '#fff',
    },
    primary: {
      main: '#5868B4',
      light: '#c6cdea',
    },
    success: {
      main: '#22c55e',
      light: '#caf1d8',
      dark: '#0e4f26',
    },
    secondary: {
      main: '#606F89',
    },
    text: {
      primary: '#495057',
      secondary: '#6c757d',
    },
    icons: {
      main: '#ff0000',
      light: '#ff0000',
      dark: '#ff0000',
    },
    border: {
      main: 'rgba(0,0,0,0.23)',
      light: '#ff0000',
      dark: '#ff0000',
    },
    table: {
      tableBg: '#F7FAFC',
      tableBorder: '#E2E8F0',
    }
  },
})

