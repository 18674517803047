import React from "react";
import {Navigate, RouteObject} from "react-router";
import {PrivateWrapper} from "@/routes/PrivateWrapper";

export const ROUTES: RouteObject[] = [
  {
    path: 'login',
    async lazy() {
      const {LoginPage} = await import("@/pages/Login/LoginPage")
      return {Component: LoginPage}
    },
  },
  {
    path: 'registration',
    async lazy() {
      const {RegistrationPage} = await import("@/pages/Registration/RegistrationPage")
      return {Component: RegistrationPage}
    }
  },
  {
    element: <PrivateWrapper />,
    children: [
      {
        index: true,
        path: '/navigator',
        async lazy() {
          const {NavigatorPage} = await import("@/pages/Navigator/NavigatorPage")
          return {Component: NavigatorPage}
        }
      },
    ]
  },
  {
    path: '*',
    element: <Navigate to="navigator" replace />,
  }
]