import React from "react";

import { ThemeProvider } from '@mui/material/styles'

import {mainLightTheme} from "@/themes/mainTheme/lightTheme";
import {ROUTES} from "@/routes/routes";
import {createBrowserRouter, RouterProvider} from "react-router-dom";

const router = createBrowserRouter(ROUTES);

export const App = () => {

  return (
    <>
      <ThemeProvider theme={mainLightTheme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </>
  )
}

