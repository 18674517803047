import {OIDCAuthIFrame} from "@proapteka/oidc-auth-iframe";
import {oAuthControllerInstance} from "@/api/instance";
import {Preloader} from "@/components/Preloader/Preloader";
import {Outlet} from "react-router";


export const PrivateWrapper = () => {
  return (
    <OIDCAuthIFrame authControllerInstance={oAuthControllerInstance} preloader={<Preloader />}>
      <Outlet />
    </OIDCAuthIFrame>
  )
}