import {themeComponents} from "@/themes/utils/components";
import {themeRootStyles} from "@/themes/utils/root";
import {createTheme} from "@mui/material/styles";

export const mainLightTheme = createTheme({
  ...themeRootStyles,
  components: themeComponents,
  palette: {
    mode: 'light',
    background: {
      default: '#eff3f8',
      paper: '#fff',
    },
    primary: {
      main: '#5868B4',
      light: '#c6cdea',
    },
    secondary: {
      main: '#606F89',
    },
    success: {
      main: '#22c55e',
      light: '#caf1d8',
      dark: '#0e4f26',
    },
    icons: {
      main: '#ff0000',
      light: '#ff0000',
      dark: '#ff0000',
    },
    border: {
      main: 'rgba(0,0,0,0.23)',
      light: '#ff0000',
      dark: '#ff0000',
    },
    text: {
      primary: '#343a40',
      secondary: '#858a8d',
    },
    table: {
      tableBg: '#F7FAFC',
      tableBorder: '#E2E8F0',
    }
  }
})