import React from 'react'
import ReactDOM from 'react-dom/client'

import { App } from './App'

import './index.css'
import 'primeicons/primeicons.css';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
      <App />
)
