import axios from 'axios'
import { passportApi} from '@/env.config'
// @ts-ignore
import qs from "qs"
import {OIDCAuthController} from "@proapteka/oidc-auth-controller";

axios.defaults.withCredentials = true;

const axiosInstance = axios.create({
  baseURL: passportApi,
  timeout: 60 * 1000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  transformRequest: [(data) => JSON.stringify(data)],
  paramsSerializer: params => qs.stringify(params, {arrayFormat: 'repeat'})
})

const publicAxiosInstance = axios.create({
  baseURL: passportApi,
  timeout: 60 * 1000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  transformRequest: [(data) => JSON.stringify(data)],
  paramsSerializer: params => qs.stringify(params, {arrayFormat: 'repeat'})
})

const oAuthControllerInstance = new OIDCAuthController({Axios: axiosInstance})

export { axiosInstance, oAuthControllerInstance, publicAxiosInstance }
